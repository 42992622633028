export const environment = {
  production: false,
  url_server_auth: process.env["URL_SERVER_AUTH"] || "",
  gatewayBaseUrl: process.env["GATEWAY_BASE_URL"] || "",
  keycloak: {
    // Url of the Identity Provider
    issuer: process.env["KEYCLOAK_ISSUER"] || "",
    // Realm
    realm: process.env["KEYCLOAK_REALM"] || "",
    clientId: process.env["KEYCLOAK_CLIENT_ID"] || "",
  },
};
