import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MenuService {

  constructor() { }

  getMenu(): Array<any> {
    const menu = [
      { 
        name: 'home', 
        path: '/home', 
        children: [] 
      },
      { 
        name: 'dashboard', 
        path: '/dashboard', 
        children: [] 
      },
      { 
        name: 'users', 
        path: '/users', 
        children: [
          { 
            name: 'User list', 
            path: '/list' 
          },
          { 
            name: 'Create User', 
            path: '/create' 
          },
        ] 
      },
      {
        name: 'transactions',
        path: '/transactions',
        children: [
          { 
            name: 'Transaction search',
            path: '/search'
          },
          { 
            name: 'Transaction create', 
            path: '/create' 
          },
          { 
            name: 'Transaction type', 
            path: '/type' 
          },
          {
            name: 'Stock',
            path: '/stock',
            children: [
              { 
                name: 'Stock list', 
                path: '/list' 
              },
              { 
                name: 'Stock create', 
                path: '/create' 
              },
              { 
                name: 'Stock group list', 
                path: '/select-group' 
              },
              { 
                name: 'Stock group create', 
                path: '/create-group' 
              },
              { 
                name: 'Stock group details', 
                path: '/group-details' 
              },
            ]
          },
          {
            name: 'Shap Shap',
            path: '/shap-shap',
            children: [
              { 
                name: 'Shap Shap list', 
                path: '/list' 
              },
              { 
                name: 'Shap Shap create', 
                path: '/create' 
              },
              { 
                name: 'Shap Shap group list', 
                path: '/select-group' 
              },
              { 
                name: 'Shap Shap group create', 
                path: '/create-group' 
              },
              { 
                name: 'Shap Shap group details', 
                path: '/group-details' 
              },
            ]
          },
          {
            name: 'Mobile Money',
            path: '/mobile-money',
            children: [
              { 
                name: 'Mobile Money list', 
                path: '/list' 
              },
              { 
                name: 'Mobile Money create', 
                path: '/create' 
              },
              { 
                name: 'Mobile Money group list', 
                path: '/select-group' 
              },
              { 
                name: 'Mobile Money group create', 
                path: '/create-group' 
              },
              { 
                name: 'Mobile Money group details', 
                path: '/group-details' 
              },
            ]
          },
          {
            name: 'Depot Zedpay',
            path: '/depot-zedpay',
            children: [
              { 
                name: 'Depot Zedpay list', 
                path: '/list' 
              },
              { 
                name: 'Depot Zedpay create', 
                path: '/create' 
              },
              { 
                name: 'Depot Zedpay group list', 
                path: '/select-group' 
              },
              { 
                name: 'Depot Zedpay group create', 
                path: '/create-group' 
              },
              { 
                name: 'Depot Zedpay group details', 
                path: '/group-details' 
              },
            ]
          },
          {
            name: 'Cash Money',
            path: '/cash-money',
            children: [
              { 
                name: 'Cash Money list', 
                path: '/list' 
              },
              { 
                name: 'Cash Money create', 
                path: '/create' 
              },
              { 
                name: 'Cash Money group list', 
                path: '/select-group' 
              },
              { 
                name: 'Cash Money group create', 
                path: '/create-group' 
              },
              { 
                name: 'Cash Money group details', 
                path: '/group-details' 
              },
            ]
          },
        ]
      },
      {
        name: 'zeyna',
        path: '/zeyna',
        children: [
          { 
            name: 'Zeyna transactions',
            path: '/transactions'
          },
          { 
            name: 'Zeyna create', 
            path: '/create' 
          },
        ]
      },
      {
        name: 'airtel',
        path: '/airtel',
        children: [
          { 
            name: 'Airtel transactions',
            path: '/transactions'
          },
        ]
      },
      {
        name: 'moov',
        path: '/moov',
        children: [
          { 
            name: 'Moov transactions',
            path: '/transactions'
          },
        ]
      },
      {
        name: 'nt',
        path: '/nt',
        children: [
          { 
            name: 'NT transactions',
            path: '/transactions'
          },
        ]
      },
      {
        name: 'zamani',
        path: '/zamani',
        children: [
          { 
            name: 'Zamani transactions',
            path: '/transactions'
          },
        ]
      },
    ];

    return menu;
  }
}
