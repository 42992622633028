import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MenuService } from '../service/menu.service';

interface BreadcrumbItem {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  standalone:true,
  imports:[
    NgFor,
    NgIf,
    RouterLink
  ],
  template:`
    <nav aria-label="breadcrumb" class="flex items-center">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/">Accueil</a>
          </li>
          <li *ngFor="let breadcrumb of breadcrumbList" class="breadcrumb-item" [class.active]="breadcrumb === breadcrumbList[breadcrumbList.length - 1]">
            <a *ngIf="breadcrumb !== breadcrumbList[breadcrumbList.length - 1]" [routerLink]="breadcrumb.path">
                {{ breadcrumb.name }}
            </a>
          <span *ngIf="breadcrumb === breadcrumbList[breadcrumbList.length - 1]">
              {{ breadcrumb.name }}
          </span>
          </li>
        </ol>
    </nav>
  `,
  styles:`
    .breadcrumb {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem 1rem;
      list-style: none;
      background-color: #e9ecef;
      border-radius: 0.25rem;
    }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.5rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/";
    }

    .breadcrumb-item.active {
      color: #6c757d;
    }
  `,
})
export class BreadcrumbComponent implements OnInit {
  name: string;
  menu: Array<any> = [];
  breadcrumbList: Array<any> = [];

  constructor(private _router: Router, private menuService: MenuService) {}

  ngOnInit() {
    this.menu = this.menuService.getMenu();
    this.listenRouting();
  }

  listenRouting() {
    let routerUrl: string, routerList: Array<string>, target: any;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;
        routerList = routerUrl.slice(1).split('?')[0].split('/');

        // On parcourt tous les segments de la route
        routerList.forEach((routerSegment, index) => {

          // Trouver l'élément correspondant dans target
          target = target.find(page => page.path.slice(1) === routerSegment);

          // Si l'élément est trouvé, on l'ajoute au breadcrumbList
          if (target) {
            this.breadcrumbList.push({
              name: target.name,
              path: (index === 0) ? target.path : `${this.breadcrumbList[index - 1].path}/${target.path.slice(1)}`
            });
          } else {
          }

          // Si ce n'est pas le dernier segment, on met à jour target avec ses enfants
          if (index + 1 !== routerList.length && target?.children) {
            target = target.children;
          }
        });
      }
    });
}
}