import { FuseNavigationItem } from '@fuse/components/navigation';

export interface Navigation {
    compact: FuseNavigationItem[];
    default: FuseNavigationItem[];
    futuristic: FuseNavigationItem[];
    horizontal: FuseNavigationItem[];
}

export const navigationItems: FuseNavigationItem[] = [
    {
      id: 'dashboards',
      title: 'Dashboards',
      subtitle: 'Transactions',
      type: 'group',
      icon: 'heroicons_outline:home',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'basic',
          icon: 'heroicons_outline:chart-pie',
          link: '/dashboard',
        },
        {
          id: 'balances',
          title: 'Balances',
          type: 'basic',
          icon: 'heroicons_outline:chart-pie',
          link: '/dashboard-balance',
        },
      ],
    },
   {
      id: 'core',
      title: 'CORE ZedPay',
      subtitle: 'Custom made application designs',
      type: 'group',
      icon: 'heroicons_outline:home',
      children: [
        {
          id: 'Accounts',
          title: 'Accounts',
          type: 'collapsable',
          icon: 'heroicons_outline:cash',
          children: [
            {
              id: 'accounts.create',
              title: 'Create',
              type: 'basic',
              link: '/accounts/create',
            },
            {
              id: 'accounts.search',
              title: 'Search',
              type: 'basic',
              link: '/accounts/search',
            },
          ],
        },
       /* {
          id: 'Transactions',
          title: 'Transactions',
          type: 'collapsable',
          icon: 'heroicons_outline:credit-card',
          children: [
            {
              id: 'transactions.create',
              title: 'Create',
              type: 'basic',
              link: '/transactions/create',
            },
            {
              id: 'transactions.search',
              title: 'Search',
              type: 'basic',
              link: '/transactions/search',
            },
          ],
        },*/
        {
          id: 'Users',
          title: 'Users',
          type: 'collapsable',
          icon: 'heroicons_outline:credit-card',
          children: [
            {
              id: 'Users.create',
              title: 'Create',
              type: 'basic',
              link: '/users/create',
            },
            {
              id: 'Users.search',
              title: 'Search',
              type: 'basic',
              link: '/users/list',
            },
          ],
        },
      ],
    },

    {
      id: 'applications',
      title: 'Applications',
      subtitle: 'Apps',
      type: 'group',
      icon: 'heroicons_outline:home',
      children: [
       /* {
          id: 'Accounts2',
          title: 'Accounts',
          type: 'collapsable',
          icon: 'heroicons_outline:cash',
          children: [
            {
              id: 'accounts.creat2',
              title: 'Create',
              type: 'basic',
              link: '/accounts/create',
            },
            {
              id: 'accounts.search2',
              title: 'Search',
              type: 'basic',
              link: '/accounts/search',
            },
          ],
        },*/
        {
          id: 'Transactions',
          title: 'Transactions',
          type: 'basic',
          icon: 'heroicons_outline:credit-card',
          link: '/transactions/search',
          /*children: [
            {
              title: 'Search',
              type: 'basic',
              link: '/transactions/search',
            },
            {
              title: 'Create',
              type: 'basic',
              link: '/transactions/create',
            },
          ],*/
        },
        {
          id: 'zeyna',
          title: 'Zeyna Transactions',
          type: 'collapsable',
          icon: 'heroicons_outline:credit-card',
          children: [
            {
              id: 'zeyna.create',
              title: 'Create',
              type: 'basic',
              link: '/zeyna/create',
            },
            {
              id: 'zeyna.transactions',
              title: 'Search',
              type: 'basic',
              link: '/zeyna/transactions',
            },
          ],
        },
      ],
    },
    {
      id: 'providers',
      title: 'Providers',
      subtitle: 'Providers',
      type: 'group',
      icon: 'heroicons_outline:home',
      children: [
       /* {
          id: 'Accounts2',
          title: 'Accounts',
          type: 'collapsable',
          icon: 'heroicons_outline:cash',
          children: [
            {
              id: 'accounts.creat2',
              title: 'Create',
              type: 'basic',
              link: '/accounts/create',
            },
            {
              id: 'accounts.search2',
              title: 'Search',
              type: 'basic',
              link: '/accounts/search',
            },
          ],
        },
        {
          id: 'Transactions2',
          title: 'Transactions',
          type: 'collapsable',
          icon: 'heroicons_outline:credit-card',
          children: [
            {
              id: 'transactions.creat2',
              title: 'Create',
              type: 'basic',
              link: '/transactions/create',
            },
            {
              id: 'transactions.searchd2',
              title: 'Search',
              type: 'basic',
              link: '/transactions',
            },
          ],
        },*/
        {
          id: 'airtel',
          title: 'Airtel Transactions',
          type: 'basic',
          link: '/airtel/transactions',
          icon: 'heroicons_outline:credit-card',

        },
        {
          id: 'moov',
          title: 'Moov Transactions',
          type: 'basic',
          icon: 'heroicons_outline:credit-card',
          link: '/moov/transactions',
        },
        {
          id: 'zamani',
          title: 'Zamani Transactions',
          icon: 'heroicons_outline:credit-card',
          type: 'basic',
          link: '/zamani/transactions',
        },
        {
          id: 'nt',
          title: 'Niger Telecoms Transactions',
          icon: 'heroicons_outline:credit-card',
          type: 'basic',
          link: '/nt/transactions',
        },
      ],
    },
];

  export const defaultNavigation: Navigation = {
    compact: navigationItems,
    default: navigationItems,
    futuristic: navigationItems,
    horizontal: navigationItems,
  };
