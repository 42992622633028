import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthGuardKc } from './guards/authGuard';
import { AuthGuard } from './guards/test';

// prettier-ignore
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Landing routes
    {
        path: '',
        canActivate: [AuthGuardKc],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadComponent:()=>import('app/modules/landing/home/home.component'),
            },
        ],
    },

    // Private routes
    {
        path: '',
        canActivate: [AuthGuardKc],
        component: LayoutComponent,
        data: {
        layout: 'classic',
        },
        /* resolve: {
        initialData: InitialDataResolver,
        },*/
        children: [
        {
            path: 'dashboard',
            loadComponent:()=>import('app/modules/landing/dashboard/dashboard.component'),
            data: { breadcrumb: 'Dashboard' },

        },
        {
          path: 'dashboard-balance',
          loadComponent:()=>import('app/modules/landing/dashboard/balance-dashboard/balance-dashboard.component'),
          data: { breadcrumb: 'Dashboard balance' },

        },

        {
            path: 'accounts',
            children: [
                {
                    path: 'search',
                    loadComponent:()=>import('app/modules/accounts/account-search/account-search.component'),
                    data: { breadcrumb: 'Account search' },
                },
                {
                    path: 'create',
                    loadComponent:()=>import('app/modules/accounts/account-search/account-search.component'),
                    data: { breadcrumb: 'Account create' },
                }
            ],
        },
        {
            path: 'transactions',
            children: [
                {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'search',
                },
                {
                  path: 'search',
                  loadComponent: () =>import('app/modules/transactions/transaction-search/transaction-search.component'),
                  data: { breadcrumb: 'Transaction search' },
                },
                {
                  path: 'create',
                  loadComponent: () =>import('app/modules/transactions/transaction-create/transaction-create.component'),
                  data: { breadcrumb: 'Transaction create' },
                },
                {
                  path: 'type',
                  loadComponent: () =>import('app/modules/transactions/transaction-type/transaction-type.component'),
                  data: { breadcrumb: 'Transaction type' },
                },
                {
                  path: 'stock',
                  children:[
                    {
                      path: '',
                      pathMatch: 'full',
                      redirectTo: 'create',
                    },
                    {
                      path:'list',
                      loadComponent: () =>import('app/modules/transactions/stock/stock-list/stock-list.component'),
                      data: { breadcrumb: 'Stock list' },
                    },
                    {
                      path:'create',
                      loadComponent: () =>import('app/modules/transactions/stock/stock-create/stock-create.component'),
                      data: { breadcrumb: 'Stock create' },
                    },
                    {
                      path:'select-group',
                      loadComponent: () =>import('app/modules/transactions/stock/stock-group-list/stock-group-list.component'),
                      data: { breadcrumb: 'Stock group list' },
                    },
                    {
                      path:'create-group',
                      loadComponent: () =>import('app/modules/transactions/stock/stock-group-create/stock-group-create.component'),
                      data: { breadcrumb: 'Stock group create' },
                    },
                    {
                      path:'group-details/:id',
                      loadComponent: () =>import('app/modules/transactions/stock/stock-group-details/stock-group-details.component'),
                      data: { breadcrumb: 'Stock group detail' },
                    }
                  ]
                },
                {
                  path: 'shap-shap',
                  children:[
                    {
                      path: '',
                      pathMatch: 'full',
                      redirectTo: 'create',
                    },
                    {
                      path:'list',
                      loadComponent: () =>import('app/modules/transactions/shap-shap/shap-shap-list/shap-shap-list.component'),
                      data: { breadcrumb: 'Shap Shap list' },
                    },
                    {
                      path:'create',
                      loadComponent: () =>import('app/modules/transactions/shap-shap/shap-shap-create/shap-shap-create.component'),
                      data: { breadcrumb: 'Shap Shap create' },
                    },
                    {
                      path:'select-group',
                      loadComponent: () =>import('app/modules/transactions/shap-shap/shap-shap-group-list/shap-shap-group-list.component'),
                      data: { breadcrumb: 'Shap Shap group list' },
                    },
                    {
                      path:'create-group',
                      loadComponent: () =>import('app/modules/transactions/shap-shap/shap-shap-group-create/shap-shap-group-create.component'),
                      data: { breadcrumb: 'Shap Shap group create' },
                    },
                    {
                      path:'group-details/:id',
                      loadComponent: () =>import('app/modules/transactions/shap-shap/shap-shap-group-details/shap-shap-group-details.component'),
                      data: { breadcrumb: 'Shap Shap group detail' },
                    }
                  ]
                },
                {
                  path: 'mobile-money',
                  children:[
                    {
                      path: '',
                      pathMatch: 'full',
                      redirectTo: 'create',
                    },
                    {
                      path:'list',
                      loadComponent: () =>import('app/modules/transactions/mobile-money/mobile-money-list/mobile-money-list.component'),
                      data: { breadcrumb: 'Mobile Money list' },
                    },
                    {
                      path:'create',
                      loadComponent: () =>import('app/modules/transactions/mobile-money/mobile-money-create/mobile-money-create.component'),
                      data: { breadcrumb: 'Mobile Money create' },
                    },
                    {
                      path:'select-group',
                      loadComponent: () =>import('app/modules/transactions/mobile-money/mobile-money-group-list/mobile-money-group-list.component'),
                      data: { breadcrumb: 'Mobile Money group list' },
                    },
                    {
                      path:'create-group',
                      loadComponent: () =>import('app/modules/transactions/mobile-money/mobile-money-group-create/mobile-money-group-create.component'),
                      data: { breadcrumb: 'Mobile Money group create' },
                    },
                    {
                      path:'group-details/:id',
                      loadComponent: () =>import('app/modules/transactions/mobile-money/mobile-money-group-details/mobile-money-group-details.component'),
                      data: { breadcrumb: 'Mobile Money group detail' },
                    }
                  ]
                },
                {
                  path: 'depot-zedpay',
                  children:[
                    {
                      path: '',
                      pathMatch: 'full',
                      redirectTo: 'create',
                    },
                    {
                      path:'list',
                      loadComponent: () =>import('app/modules/transactions/depot-zedpay/depot-zedpay-list/depot-zedpay-list.component'),
                      data: { breadcrumb: 'Depot zedpay list' },
                    },
                    {
                      path:'create',
                      loadComponent: () =>import('app/modules/transactions/depot-zedpay/depot-zedpay-create/depot-zedpay-create.component'),
                      data: { breadcrumb: 'Depot zedpay create' },
                    },
                    {
                      path:'select-group',
                      loadComponent: () =>import('app/modules/transactions/depot-zedpay/depot-zedpay-group-list/depot-zedpay-group-list.component'),
                      data: { breadcrumb: 'Depot zedpay group list' },
                    },
                    {
                      path:'create-group',
                      loadComponent: () =>import('app/modules/transactions/depot-zedpay/depot-zedpay-group-create/depot-zedpay-group-create.component'),
                      data: { breadcrumb: 'Depot zedpay group create' },
                    },
                    {
                      path:'group-details/:id',
                      loadComponent: () =>import('app/modules/transactions/depot-zedpay/depot-zedpay-group-details/depot-zedpay-group-details.component'),
                      data: { breadcrumb: 'Depot zedpay group detail' },
                    }
                  ]
                },
                {
                  path: 'cash-money',
                  children:[
                    {
                      path: '',
                      pathMatch: 'full',
                      redirectTo: 'create',
                    },
                    {
                      path:'list',
                      loadComponent: () =>import('app/modules/transactions/cash-money/cash-money-list/cash-money-list.component'),
                      data: { breadcrumb: 'Cash money list' },
                    },
                    {
                      path:'create',
                      loadComponent: () =>import('app/modules/transactions/cash-money/cash-money-create/cash-money-create.component'),
                      data: { breadcrumb: 'Cash money create' },
                    },
                    {
                      path:'select-group',
                      loadComponent: () =>import('app/modules/transactions/cash-money/cash-money-group-list/cash-money-group-list.component'),
                      data: { breadcrumb: 'Cash money group list' },
                    },
                    {
                      path:'create-group',
                      loadComponent: () =>import('app/modules/transactions/cash-money/cash-money-group-create/cash-money-group-create.component'),
                      data: { breadcrumb: 'Cash money group create' },
                    },
                    {
                      path:'group-details/:id',
                      loadComponent: () =>import('app/modules/transactions/cash-money/cash-money-group-details/cash-money-group-details.component'),
                      data: { breadcrumb: 'Cash money group detail' },
                    }
                  ]
                },
                {
                  path: 'facture',
                  children:[
                    {
                      path: '',
                      pathMatch: 'full',
                      redirectTo: 'available-invoice',
                    },
                    {
                      path:'available-invoice',
                      loadComponent: () =>import('app/modules/transactions/invoice/available-invoice/available-invoice.component'),
                    },
                    {
                      path:'nigelec',
                      children:[
                        {
                          path: '',
                          pathMatch: 'full',
                          redirectTo: 'create',
                        },
                        {
                          path:'create',
                          loadComponent: () =>import('app/modules/transactions/invoice/nigelec/nigelec-create/nigelec-create.component'),
                        },
                      ]
                    },
                  ]
                },

            ],
        },
        {
            path: 'users',
            children: [
                {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'list',
                },
                {
                  path: 'list',
                  loadComponent: () =>import('app/modules/user/user-list/user-list.component'),
                },
                {
                  path: 'create',
                  loadComponent: () =>import('app/modules/user/user-create/user-create.component'),
                },
                {
                  path: 'details',
                  loadComponent: () =>import('app/modules/user/user-details/user-details.component'),
                },
            ],
        },

        {
            path: 'zeyna',
            children: [
                {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'transactions',
                },
                {
                  path: 'transactions',
                  loadComponent: () => import('app/modules/zeyna/zeyna-transaction-list/zeyna-transaction-list.component'),
                },
                {
                  path: 'create',
                  loadComponent: () => import('app/modules/zeyna/create-stock-transaction/create-stock-transaction.component'),
                },
            ],
        },
        {
          path: 'airtel',
          children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'transactions',
              },
              {
                path: 'transactions',
                loadComponent: () => import('app/modules/providers/airtel/airtel-transaction-list/airtel-transaction-list.component'),
              },

          ],
      },
      {
        path: 'moov',
        children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: 'transactions',
            },
            {
              path: 'transactions',
              loadComponent: () => import('app/modules/providers/moov/moov-transaction-list/moov-transaction-list.component'),
            },

        ],
    },
    {
      path: 'nt',
      children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'transactions',
          },
          {
            path: 'transactions',
            loadComponent: () => import('app/modules/providers/niger-telecoms/niger-telecoms-transaction-list/niger-telecoms-transaction-list.component'),
          },

      ],

  },
  {
    path: 'zamani',
    children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'transactions',
        },
        {
          path: 'transactions',
          loadComponent: () => import('app/modules/providers/zamani/zamani-transaction-list/zamani-transaction-list.component'),
        },

    ],

},

        ],
    },

    {
        path: '',
        component: LayoutComponent,
        data: {
          layout: 'classic',
        },
        /*resolve: {
          initialData: InitialDataResolver,
        },
        */
        children: [
          {
            path: 'users',
            children: [
                {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'list',
                },
                {
                  path: 'list',
                  loadComponent: () =>import('app/modules/user/user-list/user-list.component'),
                },
                {
                  path: 'create',
                  loadComponent: () =>import('app/modules/user/user-create/user-create.component'),
                },
            ],
          },
        ],
      },
    
];
